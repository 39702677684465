import { Component, OnInit } from '@angular/core';
import { GoldenSherpaConstants } from '../../../core/constants/app.constants';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  gsLinks: any = GoldenSherpaConstants.routerLinks;

  constructor() { }

  ngOnInit() {
  }

}
