export const StateList = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'District of Columbia',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];
export const Gender = [{ key: 1, value: 'Male' }, { key: 2, value: 'Female' }, { key: 3, value: 'Other' }];
export const AgeRange = [{ key: '50-60', value: "50-60" }, { key: '60-70', value: "60-70" }, { key: '70-80', value: "70-80" }, { key: '80-90', value: "80-90" }, { key: '90-100', value: "90-100" }, { key: '100-110', value: "100-110" }];
export const Relation = [{ key: 'self', value: 'Self' }, { key: 'mom', value: 'Mom' }, { key: 'dad', value: 'Dad' },{ key: 'close-relative', value: 'Close Relative' }, { key: 'friend', value: 'Friend' }];
export const EventColors: any = {
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  grey: {
    primary: '#888888',
    secondary: '#D1D1D1'
  },
  maroon: {
    primary: '#FF5A5A',
    secondary: '#FFD5D5'
  },
  green: {
    primary: '#00BA3F',
    secondary: '#CFFFE3'
  }
};
export const EventTypes = [{
  'event_type': 1,
  'event_name': 'Live Virtual Tour',
  'color': '#f5a623',
  'class_name': 'video'
},{
  'event_type': 2,
  'event_name': 'In-Person Tour',
  'color': '#566FB8',
  'class_name': 'visit'
},{
  'event_type': 3,
  'event_name': 'Not available',
  'color': '#888888',
  'class_name': 'unavailable'
},{
  'event_type': 4,
  'event_name': 'Voice call',
  'color': '#FF5A5A',
  'class_name': 'voice_call'
},
// {
//   'event_type': 5,
//   'event_name': 'Live Virtual Tour',
//   'color': '#00BA3F',
//   'class_name': 'virtual_tour'
// }
];
export const InventoryTableHeaders = ['name', 'contact_number', 'moving_date', 'gender_value', 'care_type_name', 'care_level', 'room_type_name', 'discharge_date'];
export enum KEY_CODE {
  ESCAPE = 27,
  ENTER = 13
}
export const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const Weekdays = [{day: 1, label: 'Mon', weekday:'Monday', start_time:'09:00', end_time: '17:00', selected: true}, {day: 2, label: 'Tue', weekday:'Tuesday', start_time:'09:00', end_time: '17:00', selected: true}, {day: 3, label: 'Wed', weekday:'Wednesday', start_time:'09:00', end_time: '17:00', selected: true}, {day: 4, label: 'Thu', weekday:'Thursday', start_time:'09:00', end_time: '17:00', selected: true}];
export const Weekends = [{day: 5, label: 'Fri', weekday:'Friday', start_time:'09:00', end_time: '17:00', selected: true}, {day: 6, label: 'Sat', weekday:'Saturday', start_time:'09:00', end_time: '17:00', selected: false}, {day: 7, label: 'Sun', weekday:'Sunday', start_time:'09:00', end_time: '17:00', selected: false}]
export const Timeslots = [{label: '12:00am', val: '00:00'}, {label: '12:30am', val: '00:30'},{label: '1:00am', val: '01:00'},{label: '1:30am', val: '01:30'},{label: '2:00am', val: '02:00'},{label: '2:30am', val: '02:30'},{label: '3:00am', val: '03:00'},{label: '3:30am', val: '03:30'},{label: '4:00am', val: '04:00'},{label: '4:30am', val: '04:30'},{label: '5:00am', val: '05:00'},{label: '5:30am', val: '05:30'},{label: '6:00am', val: '06:00'},{label: '6:30am', val: '06:30'},{label: '7:00am', val: '07:00'},{label: '7:30am', val: '07:30'},{label: '8:00am', val: '08:00'}, {label: '8:30am', val: '08:30'}, {label: '9:00am', val: '09:00'}, {label: '9:30am', val: '09:30'}, {label: '10:00am', val: '10:00'},{label: '10:30am', val: '10:30'},{label: '11:00am', val: '11:00'}, {label: '11:30am', val: '11:30'}, {label: '12:00pm', val: '12:00'}, {label: '12:30pm', val: '12:30'}, {label: '1:00pm', val: '13:00'}, {label: '1:30pm', val: '13:30'}, {label: '2:00pm', val: '14:00'}, {label: '2:30pm', val: '14:30'}, {label: '3:00pm', val: '15:00'}, {label: '3:30pm', val: '15:30'}, {label: '4:00pm', val: '16:00'}, {label: '4:30pm', val: '16:30'}, {label: '5:00pm', val: '17:00'}, {label: '5:30pm', val: '17:30'}, {label: '6:00pm', val: '18:00'}, {label: '6:30pm', val: '18:30'}, {label: '7:00pm', val: '19:00'}, {label: '7:30pm', val: '19:30'}, {label: '8:00pm', val: '20:00'},{label: '8:30pm', val: '20:30'},{label: '9:00pm', val: '21:00'}, {label: '9:30pm', val: '21:30'}, {label: '10:00pm', val: '22:00'}, {label: '10:30pm', val: '22:30'}, {label: '11:00pm', val: '23:00'}, {label: '11:30pm', val: '23:30'}, {label: '11:59pm', val: '23:59'}]
// export const Timeslots = [{label: '12:00am', val: '24:00'}, {label: '12:30am', val: '24:30'}, {label: '1:00am', val: '01:00'}, {label: '1:30am', val: '01:30'}, {label: '2:00am', val: '02:00'}, {label: '2:30am', val: '02:30'}, {label: '3:00am', val: '03:00'}, {label: '3:30am', val: '03:30'}, {label: '4:00am', val: '04:00'}, {label: '4:30am', val: '04:30'}, {label: '5:00am', val: '05:00'}, {label: '5:30am', val: '05:30'}, {label: '6:00am', val: '06:00'}, {label: '6:30am', val: '06:30'}, {label: '7:00am', val: '07:00'}, {label: '7:30am', val: '07:30'}, {label: '8:00am', val: '08:00'}, {label: '8:30am', val: '08:30'}, {label: '9:00am', val: '09:00'}, {label: '9:30am', val: '09:30'}, {label: '10:00am', val: '10:00'},{label: '10:30am', val: '10:30'},{label: '11:00am', val: '11:00'}, {label: '11:30am', val: '11:30'}, {label: '12:00pm', val: '12:00'}, {label: '12:30pm', val: '12:30'}, {label: '1:00pm', val: '13:00'}, {label: '1:30pm', val: '13:30'}, {label: '2:00pm', val: '14:00'}, {label: '2:30pm', val: '14:30'}, {label: '3:00pm', val: '15:00'}, {label: '3:30pm', val: '15:30'}, {label: '4:00pm', val: '16:00'}, {label: '4:30pm', val: '16:30'}, {label: '5:00pm', val: '17:00'}, {label: '5:30pm', val: '17:30'}, {label: '6:00pm', val: '18:00'}, {label: '6:30pm', val: '18:30'}, {label: '7:00pm', val: '19:00'},{label: '7:30pm', val: '19:30'}, {label: '8:00pm', val: '20:00'}, {label: '8:30pm', val: '20:30'}, {label: '9:00pm', val: '21:00'}, {label: '9:30pm', val: '21:30'}, {label: '10:00pm', val: '22:00'}, {label: '10:30pm', val: '22:30'}, {label: '11:00pm', val: '23:00'}, {label: '11:30pm', val: '23:30'}]
export const MetaTags = {
  title: 'Best Adult Family Homes, Assisted Living, Memory Care, Respite Care, and Caregiver Information in the USA | GoldenSHERPA',
  description: ' Explore your options so you can make the right decisions. With information on senior living, adult family homes, assisted living and memory care, we’ve got suggestions to help make every day easier. And don’t miss our complete caregiving resource for tips',
  url: 'https://goldensherpa.com',
  author: 'GoldenSHERPA'
}
export const PreSetupChecklistItems = [{
  id: 1,
  title: 'Descriptive copy',
  description: 'Please have some thoughts on copy to tell us about your community.',
  icon: 'assets/img/descriptive_copy.svg'
},{
  id: 2,
  title: 'Disclosure agreement',
  description: 'As per WA state law, these are required for Assisted Living care types, and Adult Family Homes.',
  icon: 'assets/img/disclosure_agreement.svg'
},{
  id: 3,
  title: 'List of Amenities',
  description: 'Generally described as all publically available community features.',
  icon: 'assets/img/list_of_amenities.svg'
},{
  id: 4,
  title: 'List of Services',
  description: 'Extra services that may be free or paid for. Please have prices available.',
  icon: 'assets/img/list_of_services.svg'
},{
  id: 5,
  title: 'List of Staff',
  description: 'This should include staff numbers of medication technicians, registered nurses etc.',
  icon: 'assets/img/list_of_staff.svg'
},{
  id: 6,
  title: 'Photos',
  description: 'Photos required of the community, covering inside, outside, individual rooms, and common areas. Photographer can be arranged for a fee.',
  icon: 'assets/img/photos.svg'
},{
  id: 7,
  title: 'Unit Information',
  description: 'Information for each unique unit/room type. (Pricing, room type, description).',
  icon: 'assets/img/unit_information.svg'
}]
