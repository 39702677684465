export const environment = {
  production: true,
  baseUrl: 'apidev.goldensherpa.com',
  port: '',
  name: 'Development',
  facebookAppId: '262100878060648',
  google_analytics_id: 'UA-145470270-1',
  agoraId: 'c25316eb05ea43699728843bb0cd4aee',
  plaid_public_key: '487318b5809ee0547a7775c3875a27',
  stripe_key: 'pk_test_VXNDozQPHMzgGz6SKOPlEvnQ00Q3STqDLt',
  sentry_dsn: 'https://e6a9bf6a7a5e469ea2c6c3c43f400105@sentry.io/2412818',
  googleClientId: '852996733303-o7ogo88fj9k03jeshojva2lbqnfdv4pp.apps.googleusercontent.com',
  super_admin_company: {
    id: '8f9eb7db-c8ca-4300-8c69-2dd7455e6646',
    email: 'organisation@yopmail.com',
    name: 'GoldenSherpa'
  }
};
