import { environment } from "../../../environments/environment";

export const GoldenSherpaConstants = {
	apiUrl: `//${environment.baseUrl}${environment.port ? environment.port : ''}/api`,
	version: 'v1',
	environmentName: environment.name,
	googleClientId: environment.googleClientId,
	facebookAppId: environment.facebookAppId,
	ga_id: environment.google_analytics_id,
	agoraId: environment.agoraId,
	stripeKey: environment.stripe_key,
	plaidPublicKey: environment.plaid_public_key,
	SENTRY_DSN: environment.sentry_dsn,
	super_admin_company: environment.super_admin_company,
	passwordValidators: {
		// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
		regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
		min: 8
	},
	urlValidators:{
		regex: '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})'
	},
	urlRegex: `https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)`,
	gapiKey: 'AIzaSyB0H4RxjNvOiug0tyCIHTAfKCtL6C7fQ1M',
	phoneRegex: '([0-9]{10})',
	faxRegex: '/+1(|.|-)[2-9][0-9]{2}(|.|-)[0-9]{3}(|.|-)[0-9]{4}/',
	surveyLimit: 100,
	defaultRequestDays: 30,
	mapOptions: {
		types: [],
		componentRestrictions: { country: 'US' }
	},
	zoho_help_center: 'https://blog.goldensherpa.com',
	zoho_crawled_contact_host: 'https://zfrmz.com/RotRbX8Bmugc64fgSPFE',
	subscription_types: {
		single: 1,
		multiple: 2
	},
	payment_modes: {
		ach: 1,
		card: 2
	},
	adDisplayConstants:{
		communityDetailsPage: {
			id: 1,
			positions:{
				top: 1,
				bottom: 2
			}
		},
		resultsPage: 2
	},
	search_constants: { 
		// in miles
		search_within_area: {
			enable: true,
			default_radius: 50
		}
	},
	events: {
		schedule_call: {
			type: 1,
			duration: 60,
			name: 'Live Virtual Tour',
			can_create: ['customer', 'organization'],
			conditional_cases: {
				depends_on_community_virtual_tour_flag: true,
			}
		},
		schedule_visit: {
			type: 2,
			duration: 60,
			name: 'In-Person Tour',
			can_create: ['customer', 'organization']
		},
		not_available: {
			type: 3,
			name: 'Not available',
			can_create: ['organization']
		},
		voice_call:{
			type: 4,
			name: 'Phone Call',
			can_create: ['customer','organization']
		},
		// virtual_tour: {
		// 	type: 5,
		// 	name: 'Live Virtual Tour',
		// 	can_create: ['customer','organization'],
		// 	conditional_cases: {
		// 		depends_on_community_virtual_tour_flag: true,
		// 	}
		// }
	},
	services: {
		photography:{
			type: 1,
			name: 'photography'
		}
	},
	routerLinks: {
		appBase: '/',
		home: '/home',
		login: '/login',
		signup: '/signup',
		schedule: '/schedules',
		organizations: '/organizations',
		verify: '/signup/email/verify/',
		emailConfirmation: 'signup/email/confirm',
		primaryDetails: '/signup/primary_details',
		mailingAddress: '/signup/mailing_address',
		forgotPassword: '/forgot_password',
		resetpasswordSent: 'reset_password/sent/',
		privacyPolicy: '/privacy_policy',
		mission: '/mission',
		resetPassword: '/reset_password',
		subscriptionBase: '/subscription',
		transactions: '/transactions',
		subscriptionTypes: {
			base: '/pay',
			card: '/card',
			ach: '/ach'
		},
		confirmation: '/confirmation',
		survey: '/survey',
		meeting: '/meeting',
		notfound: '/404',
		profile: {
			base: '/home/profile',
			password: '/password',
			notification: '/notification',
			subscription: '/subscription',
			payment_settings: '/paymentsettings'
		},
		customer: {
			base: '/customer',
			wishlist: '/wishlist',
			viewFacility: '/facility',
			viewInterests: '/interests',
			my_schedules: '/my-schedules',
			viewSavedSearch: '/saved-search',
			profile: {
				base: '/profile',
				changePassword: '/change-password',
				notifications: '/notifications'
			}
		},
		facility: {
			addFacility: {
				base: '/home/facility/',
				providerInfo: '/provider-info',
				manageServices: '/manage-services',
				manageRooms: '/manage-rooms',
				manageStaff: '/manage-staff'
			},
			viewFacility: {
				base: '/home/facility/view',
				overview: '/overview',
				inventory: '/inventory',
				availability: '/availability',
				interestList: '/interestlist',
				waitlist: '/waitlist',
			}
		},
		results: {
			base: '/results',
			viewFacility: '/facility'
		},
		admin: {
			base: '/admin',
			dashboard: '/admin/home',
			overview: '/admin/home/overview',
			requests: '/admin/home/requests',
			promotions: '/admin/home/promotions',
			dashboardCompanies: '/admin/home/companies',
			loadData: '/admin/home/load-data',
			adminFacilitylist: '/admin/home/facilitylist',
		},
		partner: {
			base: '/partner',
			register: '/register',
			login: '/login',
			verify: '/email/verify/',
			home: '/home',
			transactions: '/transactions',
			emailConfirmation: '/email/confirm',
			primaryDetails: '/onboarding',
			profile: '/profile',
			paymentSettings: '/payment-settings',
			changePassword: '/change-password',
			createad: '/advertisment',
			previewAd: '/preview',
			viewAd: {
				base: '/view-ad',
				overview: '/overview',
				statDetails: '/stat-details'
			},
			subscription: {
				base: '/subscription',
				card_payment: '/pay/card',
				ach_payment: '/pay/ach',
				confirmation: '/confirmation'
			}
		}
	},
	userType: {
		admin: 1,
		customer: 2,
		organization: 3,
		advertiser: 4
	},
	userRoleIds: {
		admin: 1,
		manager: 2,
		staff: 3,
		advertiser: 4
	},
	userRoles: [{role: 'Manager', id: 2}, {role: 'Staff', id: 3}],
	uploadKeys: {
		'facility-license': 'license',
		'facility-pictures': 'facility',
		'room-pictures': 'room',
		'care-pictures': 'care',
		'profile-picture': 'profile',
		'upload-senior': 'senior/celery/task',
		'partner-logo' : 'partner',
		'ad-banner' : 'handout',
		'admin-load-data': 'new-facility-data'
	},
	fileuploads: {
		maxFileSize: 10,
		maxVideoSize: 50,
		typesAllowed: {
			image: [ 'image/png', 'image/jpeg' ],
			csv: [ 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
			doc: [ 'application/pdf' ],
			video: ['video/mp4', 'video/mov', 'video/wmv', 'video/avi']
		}
	},
	featureFlags: {
		staffRatio: {
			min: 2,
			on: true
		},
		clone_feature:{
			on: true
		},
		deactivate_facility:{
			on: true,
			type: 'manual'
			// auto for auto deactivate.
		},
		addition_service:{
			prompt: false,
		},
		subscription:{
			// change type to 2 if it's multiple subscription system
			type: 1
		},
		cake_walk_admin:{
			facility_features: {
				can_upload_seniors_inbulk: false,
				can_view_residents: true
			}
		}
	},
	logo_map: {
		header_light: 'assets/img/logos/gs-logo-horizontal-white.svg',
		header_dark: 'assets/img/logos/gs-logo-horizontal-black.svg',
		footer: 'assets/img/logos/gs-logo-white.svg'
	},
	sample_templates:{
		community_description: 'XYZ Place is a full-service residential community with rehabilitation and long-term skilled nursing and memory care centers. Our rehabilitation center has licensed certified rehabilitation professionals; our memory care and nursing centers provide 24-hour licensed nursing care',
		provider_info_care_description: 'ABC House is a small, private assisted-living home providing one-on-one personal care in a family-like environment. On call nurses visit as needed.',
	},
	social_links:{
		facebook: 'https://www.facebook.com/MyGoldenSHERPA/',
		twitter: 'https://twitter.com/MyGoldenSHERPA',
		linked_in: 'https://www.linkedin.com/company/golden-sherpa',
		instagram: 'https://www.instagram.com/mygoldensherpa/'
	},
	templates:{
		senior_upload_sample: 'https://goldensherpa-assest.s3-us-west-2.amazonaws.com/media/csv/senior_uploader_template.xlsx'
	}
};
