import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SortByPipe } from './pipes/sortby.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SlideshowModule } from 'ng-simple-slideshow';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatRippleModule } from '@angular/material/core';
import { HeaderComponent } from './components/header/header.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ModalComponent } from './components/customer-modal/modal.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { GsDatepickerComponent } from './components/gs-datepicker/gs-datepicker.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { AgoraMeetingComponent } from './components/agora-meeting/agora-meeting.component';
import { GeneralFooterComponent } from './components/general-footer/general-footer.component';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class'; 
import { VideoUploaderComponent } from './components/video-uploader/video-uploader.component';
import { AdvanceFilterComponent } from './components/advance-filter/advance-filter.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CustomerModalService } from './components/customer-modal/services/customer-modal.service';
import { VideoViewerModalComponent } from './components/video-viewer-modal/video-viewer-modal.component';
import { SaveSearchComponent } from './components/customer-modal/components/save-search/save-search.component';
import { ViewImagesComponent } from './components/customer-modal/components/view-images/view-images.component';
import { SearchModalComponent } from './components/customer-modal/components/search-modal/search-modal.component';
import { CustomerInfoComponent } from './components/customer-modal/components/customer-info/customer-info.component';
import { ShowInterestComponent } from './components/customer-modal/components/show-interest/show-interest.component';
import { ScheduleCallComponent } from './components/customer-modal/components/schedule-call/schedule-call.component';
import { CustomerSignupComponent } from './components/customer-modal/components/customer-signup/customer-signup.component';
import { CustomerSigninComponent } from './components/customer-modal/components/customer-signin/customer-signin.component';
import { AboutCareLevelsComponent } from './components/customer-modal/components/about-care-levels/about-care-levels.component';
import { CustomerRegisterComponent } from './components/customer-modal/components/customer-register/customer-register.component';
import { PostRegistrationComponent } from './components/customer-modal/components/post-registration/post-registration.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import {DateTimeAdapter, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime/date-time/adapter/date-time-adapter.class';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    SortByPipe,
    TruncatePipe,
    ModalComponent,
    HeaderComponent,
    ToasterComponent,
    MainHeaderComponent,
    SaveSearchComponent,
    MainFooterComponent,
    FileUploaderComponent,
    CustomerInfoComponent,
    GeneralFooterComponent,
    AdvanceFilterComponent,
    CustomerSignupComponent,
    CustomerSigninComponent,
    ChangePasswordComponent,
    CustomerRegisterComponent,
    PostRegistrationComponent,
    AboutCareLevelsComponent,
    GsDatepickerComponent,
    ScheduleCallComponent,
    ShowInterestComponent,
    AgoraMeetingComponent,
    ViewImagesComponent,
    NotFoundComponent,
    SearchModalComponent,
    EmptyStateComponent,
    VideoUploaderComponent,
    VideoViewerModalComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    SlideshowModule,
    GooglePlaceModule,
    OwlDateTimeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    OwlNativeDateTimeModule,
    NgxMaskModule.forRoot(),
    MatPasswordStrengthModule,
    DeviceDetectorModule.forRoot(),
  ],
  exports: [
    SortByPipe,
    TruncatePipe,
    NgxMaskModule,
    ModalComponent,
    MatRippleModule,
    MatSelectModule,
    SlideshowModule,
    HeaderComponent,
    ToasterComponent,
    OwlDateTimeModule,
    EmptyStateComponent,
    MainHeaderComponent,
    MainFooterComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    ViewImagesComponent,
    FileUploaderComponent,
    AgoraMeetingComponent,
    VideoUploaderComponent,
    GeneralFooterComponent,
    AdvanceFilterComponent,
    ChangePasswordComponent,
    VideoViewerModalComponent,
  ],
  providers: [CustomerModalService,
    {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
  ]
})
export class SharedModule { }
