import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { LocalStoreService } from './localstore.services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { GoldenSherpaConstants } from '../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private _localstoreService: LocalStoreService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  verifyEmail(data: any): any {
    return this.http.post(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/confirm/email/`, data).pipe(map((res) => {
      return res;
    }));
  }
  onboardOrg(data: any): any {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`
      })
    };
    return this.http.post(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/company/`, data, httpOptions).pipe(map((res) => {
      return res;
    }));
  }
  customerProfile(data: any): any {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`
      })
    };
    return this.http.put(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/senior/citizen/update/`, data, httpOptions).pipe(map((res) => {
      return res;
    }));
  }
  fecthCustomerProfileData() {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`
      })
    };
    return this.http.get(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/senior/citizen/data/`, httpOptions).pipe(map((res) => {
      return res;
    }));
  }
  updateCustomerProfileData(data: any): any {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`
      })
    };
    return this.http.put(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/customer/profile/update/`, data, httpOptions).pipe(map((res) => {
      return res;
		}));
  }
  updateSeniorDetails(data: any, method?: any, id?:string): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`
      })
    };
    if (method == 'PUT') {
      return this.http.put(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/senior/citizen/${id}`, data, httpOptions).pipe(map((res) => {
        return res;
      }));
    } else {
      return this.http.post(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/senior/citizen/`, data, httpOptions).pipe(map((res) => {
				return res;
			}));
    }
  }

  removeSeniorDetails(seniorId) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getToken()}`
      })
    };
    return this.http.delete(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/senior/citizen/${seniorId}`, httpOptions).pipe(map((res) => {
      return res;
    }));
  }

  getResetEmail(data: any): any {
    return this.http.post(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/auth/password/reset/`, data).pipe(map((res) => {
      return res;
    }));
  }
  resetPassword(data: any): any {
    return this.http.post(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/password/reset/`, data).pipe(map((res) => {
      return res;
    }));
  }
  sendReverificationEmail(data:any):any{
    return this.http.post(`${GoldenSherpaConstants.apiUrl}/${GoldenSherpaConstants.version}/auth/re-verify/email/`, data).pipe(map((res) => {
      return res;
    }));
  }
  setSession(sessionObj) {
    if(sessionObj.token) this.setToken(sessionObj.token);
    if(sessionObj.full_name) this.setName(sessionObj.full_name);
    if(sessionObj.email) this.setEmail(sessionObj.email);
    if(sessionObj.uuid) this.setUUID(sessionObj.uuid);
    if(sessionObj.user_type) this._localstoreService.putStore(sessionObj.user_type, 'user_type');
}
  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }
  setName(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('Username', name);
    }
  }
  getName() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('Username');
    }
  }
  getUserType() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('user_type');
    }
  }
  getUserRole() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('role');
    }
  }
  setEmail(email: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('Email', email);
    }
  }
  getEmail() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('Email');
    }
  }
  getProfilePic() {
    if (isPlatformBrowser(this.platformId)) {
      var profile_pic = localStorage.getItem('profile_pic')
      if (['undefined'].includes(profile_pic)) {
        profile_pic = null
      }
      return profile_pic;
    }
  }
  serProfilePic(profile_pic: any) {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.setItem('profile_pic', profile_pic);
    }
  }
  deleteEmail() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('Email');
    }
  }
  setUUID(id: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('uuid', id);
    }
  }
  getUUID() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('uuid');
    }
  }
  setToken(token: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('Authorization', token);
    }
  }
  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('Authorization');
    }
  }
  isLoggedIn(type?: string) {
    if (isPlatformBrowser(this.platformId)) {
      if(type && type == 'org'){
        if (type == 'org') {
          return this.getToken() !== null && Number(localStorage.getItem('user_type')) == 3;
        } else {
          return this.getToken() !== null && Number(localStorage.getItem('user_type')) == 2;
        }
      }
      else if(type && type == 'admin'){
        return this.getToken() !== null && Number(localStorage.getItem('user_type')) == GoldenSherpaConstants.userType.admin;
      }
      else if(type && type == 'customer'){
        return this.getToken() !== null && Number(localStorage.getItem('user_type')) == GoldenSherpaConstants.userType.customer;
      }
      else {
        return this.getToken() ? true : false;
      }
    }
  }
}
