import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { BrowserModule, Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { AuthService } from './core/services/auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { PendingChangesGuard } from './core/services/changes.gaurd';
import { GoldenSherpaConstants } from './core/constants/app.constants';
// import { AngularAgoraRtcModule, AgoraConfig } from 'angular-agora-rtc';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToasterService } from './shared/components/toaster/toaster.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RefreshTokenInterceptor } from './core/services/httpConfigInterceptor.interceptor';

import { Subject, combineLatest, merge, race } from 'rxjs';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { IsCustomer, IsOrganizationAndIsAdmin } from './core/services/auth.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

// import { MetaLoader, MetaStaticLoader, PageTitlePositioning, MetaModule } from '@ngx-meta/core/src';
// import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from "angularx-social-login";

import * as Sentry from "@sentry/browser";
// import { NgChartsModule } from 'ng2-charts';

Sentry.init({
  dsn: GoldenSherpaConstants.SENTRY_DSN,
  environment: GoldenSherpaConstants.environmentName
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}

// const agoraConfig: AgoraConfig = {
//   AppID: GoldenSherpaConstants.agoraId,
// };

// let config = new SocialAuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(GoldenSherpaConstants.googleClientId)
//   },{
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(GoldenSherpaConstants.facebookAppId)
//   }
// ]);

let config = [];

export function provideConfig(){
  return config;
}

// export function metaFactory(): MetaLoader {
//   return new MetaStaticLoader({
//     pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
//     pageTitleSeparator: ' - ',
//     applicationName: '',
//     defaults: {
//       title: '',
//       keywords: '',
//       description: '',
//       'og:title': '',
//       'og:description': '',
//       'og:image': '',
//       'og:url': '',
//     }
//   });
// }


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'results',
    loadChildren: () => import('./modules/results/results.module').then(m => m.ResultsModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [IsOrganizationAndIsAdmin]
  },
  {
    path: 'customer',
    loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
    canActivate: [IsCustomer]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'partner',
    loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule),
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    // SocialLoginModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  //   MetaModule.forRoot({
  //    provide: MetaLoader,
  //    useFactory: (metaFactory),
  //  }),
    RouterModule.forRoot(routes, { useHash: false }),
    // AngularAgoraRtcModule.forRoot(agoraConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    // NgChartsModule
  ],
  providers: [
    Meta,
    AuthService,
    ToasterService,
    // { provide: SocialAuthServiceConfig, useFactory: provideConfig },
    // { provide: NgChartsConfiguration, useValue: { generateColors: false }},
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    PendingChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
