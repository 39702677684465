import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaTags } from '../constants/static.constants';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(private title: Title, private _meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  addMetaTags() {
    this._meta.addTag({ name: 'title', content: MetaTags.title });
    this._meta.addTag({ name: 'description', content: MetaTags.description });
    this._meta.addTag({ name: 'og:title', content: MetaTags.title }, true);
    this._meta.addTag({ property: 'og:title', content: MetaTags.title }, true),
    this._meta.addTag({ name: 'description', content: MetaTags.title }, true);
    this._meta.addTag({ name: 'og:description', content: MetaTags.description }, true);
    this._meta.addTag({ property: 'og:description', content: MetaTags.description }, true);
    this._meta.addTag({ name: 'og:url', content: MetaTags.url }, true);
    this._meta.addTag({ property: 'og:url', content: MetaTags.url }, true);
    this._meta.addTag({ name: 'keywords', content: 'Goldensherpa, GoldenSHERPA, Golden Sherpa, goldensherpa, senior living, platform, adult homes, assisted living, old age living, senior living tool in US, USA, senior living manangement tool' }, true);
    this._meta.addTag({ name: 'author', content: MetaTags.author }, true);
  }

  updateMetaTags(tags) {
    tags.map(x => {
      this._meta.updateTag(x);
    })
  }
}
