declare let ga: Function;
import { SwUpdate } from '@angular/service-worker';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { SEOService } from './core/services/seo.service';
import { RoutingState } from './core/services/routingState';
import { GoldenSherpaConstants } from './core/constants/app.constants';
import { Component, Inject, Renderer2, ElementRef, PLATFORM_ID } from '@angular/core';
import { CanonicalService } from './core/services/canonical.service';
// Begin - Andre Araujo - 2023-06-28 - New Google tag management
import { filter } from 'rxjs/operators';
declare var gtag;
// End - Andre Araujo - 2023-06-28 - New Google tag management

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'GoldenSHERPA';
  update: boolean = false;
  trackingCode: any = GoldenSherpaConstants.ga_id;

  constructor(
    private router:Router,
    routingState: RoutingState,
    private swUpdate: SwUpdate,
    private _seoService: SEOService,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private canonicalService: CanonicalService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
      ){
    swUpdate.available.subscribe(event => {
      swUpdate.activateUpdate().then(() => {
        document.location.reload()
      });
    })
   if (isPlatformBrowser(this.platformId)) {
     this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
      // const script = this.renderer.createElement('script') as HTMLScriptElement;
      // script.src = `//www.googletagmanager.com/gtag/js?id=${this.trackingCode}`;
      // script.async = true;
      // script.defer = true;
      // this.renderer.appendChild(this.el.nativeElement, script);
      // const mapsScript = this.renderer.createElement('script');
      // mapsScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyB0H4RxjNvOiug0tyCIHTAfKCtL6C7fQ1M&libraries=places&language=en";
      // mapsScript.async = true;
      // mapsScript.defer = true;
      const script2 = this.renderer.createElement('script') as HTMLScriptElement;
      // const scriptBody = this.renderer.createText(`
      //   window.dataLayer = window.dataLayer || [];
      //   function gtag() {
      //     dataLayer.push(arguments);
      //   }
      //   gtag('js', new Date());
      //
      //   gtag('config', '${this.trackingCode}');
      // `);
      const zohoScript = this.renderer.createText(
        `window.ZohoHCAsap = window.ZohoHCAsap || function(a, b) {
          ZohoHCAsap[a] = b;
        };
        (function() {
          var d = document;
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.defer = true;
          s.src = "https://desk.zoho.com/portal/api/web/inapp/407549000000164001?orgId=691316777";
          d.getElementsByTagName("head")[0].appendChild(s);
        })();`
      )
      // this.renderer.appendChild(script2, scriptBody);
      // this.renderer.appendChild(script2, mapsScript);
      this.renderer.appendChild(script2, zohoScript);
      this.renderer.appendChild(this.el.nativeElement, script2);
    }
    routingState.loadRouting();
    this._seoService.addMetaTags();
    // Begin - Andre Araujo - 2023-06-28 - New Google tag management
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event:NavigationEnd) => {
      gtag('config', 'G-JCZ711KG2W',{
        'page_path' : event.urlAfterRedirects
      });
    });
    // End - Andre Araujo - 2023-06-28 - New Google tag management
  }
  ngOnInit() {
    this.canonicalService.setCanonicalURL();
  }
}
