import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerModalService {
  public modalData = new BehaviorSubject({
    show: false,
    title: '',
    size: '',
    component: '',
    data: {}
  });
  public profileData = new BehaviorSubject(undefined);
  public modalMediator = new BehaviorSubject(undefined);
  public agoraAuthData = new BehaviorSubject(undefined);

  constructor() { }
  insertData(data: any) {
    this.modalData.next(data)
  }
  updateMediator(data: any) {
    this.modalMediator.next(data);
  }
  updateProfileData(data: any) {
    this.profileData.next(data);
  }
  updateAgoraAuth(data: any){
    this.agoraAuthData.next(data);
  }
}
