import {Router} from "@angular/router";
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GoldenSherpaConstants } from '../constants/app.constants';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router:Router,private auth:AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if (this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/login'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsOrganizationUser implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserType()) == 3) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/login'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsCustomer implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserType()) == 2) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsAdmin implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserType()) == GoldenSherpaConstants.userRoleIds.admin) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/admin/cake-walk'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsOrganizationAndIsAdmin implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserType()) == GoldenSherpaConstants.userType.admin || Number(this.auth.getUserType()) == GoldenSherpaConstants.userType.organization) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/login'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsStaff implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserRole()) == GoldenSherpaConstants.userRoleIds.staff) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/home'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsManager implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserRole()) == GoldenSherpaConstants.userRoleIds.manager) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/home'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}

@Injectable({
  providedIn: 'root'
})
export class IsAdvertiser implements CanActivate {
  constructor(private router:Router,private auth:AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       if ((Number(this.auth.getUserType()) == GoldenSherpaConstants.userRoleIds.advertiser) && this.auth.getToken()) {
           return true;
       }
       this.router.navigate(['/partner/login'], {queryParams: {redirect: location.pathname}});
       return false;
   }
}
