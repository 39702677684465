import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse,HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    constructor(
      public auth: AuthService,
      private router:Router
    ) {}
      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
              if (event.status === 204 && this.router.url.split('/')[2] != 'facility') {
               this.router.navigate(['/404']);
             }
          }
        }, error => {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                  this.auth.logout();
                  this.router.navigate(['/login']);
                  return false;
                }
              }
        })
      )
    }
}
