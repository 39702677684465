import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ToasterService {
  public toasterData = new BehaviorSubject({
    show: false,
    status: '',
    title: '',
    body: '',
    dismissTime: '',
    autodismiss: false
  });
  insertData(data: any) {
    this.toasterData.next(data)
  }
}
