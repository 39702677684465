import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
  putStore(obj, key) {
    if (isPlatformBrowser(this.platformId)) {
      const stringifiedObj = JSON.stringify(obj);
      localStorage.setItem(key, stringifiedObj);
    }
  }
  putStoreGeneral(val, key){
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, val);
    }
  }
  getStore(key) {
    if (isPlatformBrowser(this.platformId)) {
      const stringifiedObj = localStorage.getItem(key);
      return JSON.parse(stringifiedObj);
    }
  }
  getStoreGeneral(key){
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(key);
    }
  }
  deleteItem(key){
    if (isPlatformBrowser(this.platformId)) {  
      localStorage.removeItem(key);
    }
  }
}
